import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import Config from '@/store/modules/Config'

export default new Vuex.Store({
  modules: {
    Config,
  }
})

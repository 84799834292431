import _ from 'lodash'
import axios from '@/lib/energy-dashboard-axios'

// API calls

const apiConfig = `
  DayState show
  HomeState show
`.trim().split("\n").map(l => l.trim()).map(l => l.split(/\s+/))

export default _.fromPairs(apiConfig.map(([name, ...actionNames]) => {
  return [name, _.fromPairs(actionNames.map(actionName => {
    // Convert title case to param case and pluralize
    const objecPathName = name.replace(/^./, m => m.toLowerCase()).replace(/[A-Z]/g, m => "-" + m.toLowerCase()) + 's'

    let action = null
    if (actionName === 'create') {
      action = ({params}) => axios.post(`/${objecPathName}`, params)
    } else if (actionName === 'index') {
      action = ({params} = {}) => axios.get(`/${objecPathName}`, {params})
    } else if (actionName === 'show') {
      action = ({id, params}) => axios.get(`/${objecPathName}/${id}`, {params})
    } else if (actionName === 'update') {
      action = ({id, params}) => axios.patch(`/${objecPathName}/${id}`, params)
    } else if (actionName === 'destroy') {
      action = ({id, params}) => axios.delete(`/${objecPathName}/${id}`, params)
    }
    return [actionName, action]
  }))]
}))

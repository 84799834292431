import axios  from 'axios'

const energyDashboardAxios = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX
})
// Serialize query parameters with qs
import qs from 'qs'
energyDashboardAxios.defaults.paramsSerializer = params => qs.stringify(params, {indices: false})

energyDashboardAxios.interceptors.request.use(config => {
  return config
})
energyDashboardAxios.interceptors.response.use(
  response => {
    return response
  },
  err => {
    return Promise.reject(err)
  }
)

export default energyDashboardAxios

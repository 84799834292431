<template>
  <v-container
      fill-height
      class="py-0"
      >
    <v-row
        class="text-center"
        style="height: 100%"
        >
      <v-col
          cols="12"
          class="pb-0"
          align="center"
          >
        <canvas
            ref="chart"
            :width="`${viewportWidthPx}px`"
            :height="`${viewportHeightPx - 80}px`"
            />
        <div class="d-flex align-center justify-center white--text mt-2">
          <v-btn 
              @click="moveDay(-1)"
              small
              fab
              :loading="loading"
              >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span
              class="mx-3"
              style="line-height: 1.3rem"
              >
            <v-dialog
                v-model="dateDialog"
                :close-on-content-click="false"
                persistent
                transition="scale-transition"
                max-width="400px"
                >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="computedDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    dark
                    class="pt-0"
                    style="width: 8rem"
                    :loading="loading"
                    />
              </template>
              <v-date-picker
                  v-model="computedDate"
                  @input="dateDialog = false"
                  />
            </v-dialog>
          </span>
          <v-btn 
              @click="moveDay(1)"
              small
              fab
              :loading="loading"
              >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="sass">
</style>

<script>
import dayjs from 'dayjs'
import _ from 'lodash'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import zoomPlugin from 'chartjs-plugin-zoom'
import Vuex from 'vuex'
import api from '@/lib/api'
Chart.register(zoomPlugin)

export default {
  name: 'Daily',

  components: {
  },
  data() {
    return {
      state: {},
      rollup: {},
      previousDayState: {},
      previousDayRollup: {},
      dayOffset: 0,
      chart: null,
      loading: false,
      dateDialog: false,
      chartConfig: null,
    }
  },
  async created() {
    setInterval(this.updateData, 60000)
    this.updateData()
  },
  mounted() {
    this.chartConfig = {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Solar',
            backgroundColor: 'yellow',
            pointRadius: 2,
            borderColor: 'yellow',
          },
          {
            label: 'Solar Previous',
            backgroundColor: '#ffff0022',
            pointRadius: 2,
            borderColor: '#ffff0022',
          },
          {
            label: 'Battery',
            backgroundColor: 'green',
            pointRadius: 2,
            borderColor: 'green'
          },
          {
            label: 'Grid',
            backgroundColor: 'grey',
            pointRadius: 2,
            borderColor: 'grey'
          },
          {
            label: 'Home',
            backgroundColor: 'blue',
            pointRadius: 2,
            borderColor: 'blue'
          },
        ],
      },
      options: {
        tension: 0.2,
        scales: {
          x: {
            type: 'timeseries',
            time: {
              displayFormats: {
                quarter: 'MMM YYYY'
              }
            }
          },
          y: {
            ticks: {
              // eslint-disable-next-line no-unused-vars
              callback(value, index, ticks) {
                const whole = value.toFixed(1) == value
                return `${whole ? value : value.toFixed(1)}kWh`
              },
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              boxWidth: 10,
            },
          },
          zoom: {
            pan: {
              enabled: true,
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            },
          },
          tooltip: {
            callbacks: {
              label(context) {
                let text = [`${context.dataset.label}: ${context.formattedValue}kWh`]
                if (context.dataset.label.match(/^Solar/)) {
                  const solarDataset = context.chart.data.datasets.find(d => d.label.match(/^Solar \(/))
                  const previousSolarDataset = context.chart.data.datasets.find(d => d.label.match(/^Solar Previous \(/))
                  let solarSum = 0
                  let previousSolarSum = 0
                  for (let i in context.dataset.data.slice(0, context.dataIndex)) {
                    const solarD = solarDataset.data[i]
                    const previousSolarD = previousSolarDataset.data[i]
                    solarSum += parseFloat(parseFloat(solarD.y).toFixed(1)) * (1 / 12)
                    previousSolarSum += parseFloat(parseFloat(previousSolarD.y).toFixed(1)) * (1 / 12)
                  }
                  text.push(`Solar Cumulative: ${solarSum.toFixed(1)}`)
                  text.push(`Solar Previous Cumulative: ${previousSolarSum.toFixed(1)}`)
                } else {
                  const dataset = context.dataset
                  let sum = 0
                  for (let i in dataset.data.slice(0, context.dataIndex)) {
                    const d = dataset.data[i]
                    sum += parseFloat(parseFloat(d.y).toFixed(1)) * (1 / 12)
                  }
                  text.push(`Cumulative: ${sum.toFixed(1)}`)
                }
                return text
              },
            },
          },
        },
      }
    };
    this.chart = new Chart(
      this.$refs.chart,
      this.chartConfig,
    )
  },
  computed: {
    ...Vuex.mapGetters('Config', ['viewportWidthPx', 'viewportHeightPx']),
    labels() {
      let labels = []
      for (let i = 0; i < 24 ; i++) {
        labels.push(dayjs().add(this.dayOffset, 'days').startOf('day').add(i, 'hours').format('ha'))
      }
      return labels
    },
    computedDate: {
      get() { return dayjs().add(this.dayOffset, 'days').format('YYYY-MM-DD') },
      set(v) { this.moveDay(Math.ceil( this.dayOffset * -1 + dayjs(v).diff(dayjs(), 'day', true))) },
    },
    previousDayDataByTimestamp() {
      return _.keyBy(
        _.map(
          _.filter(this.previousDayState.time_series, t => t.battery_power || t.grid_power || t.solar_power),
          ts => ({...ts, timestamp: dayjs(ts.timestamp).add(1, 'day').format()})
        ),
        'timestamp'
      )
    },
    dataByTimestamp() {
      return _.keyBy(_.filter(this.state.time_series, t => t.battery_power || t.grid_power || t.solar_power), 'timestamp')
    },
    desiredTimestamps() {
      const dayStart = dayjs().add(this.dayOffset, 'days').startOf('day')
      const dayEnd = dayjs().add(this.dayOffset, 'days').endOf('day')
      return _.filter(_.map(_.toPairs(this.state.time_series), '[1].timestamp'), ts => dayjs(ts).isAfter(dayStart) && dayjs(ts).isBefore(dayEnd))
    },
    chartData() {
      return {
        'Battery': _.map(this.desiredTimestamps, desired => ({x: desired, y: (parseFloat(_.get(this.dataByTimestamp, [desired, 'battery_power'])) / 1000).toFixed(1)})),
        'Grid': _.map(this.desiredTimestamps, desired => ({x: desired, y: (parseFloat(_.get(this.dataByTimestamp, [desired, 'grid_power'])) / 1000).toFixed(1)})),
        'Solar': _.map(this.desiredTimestamps, desired => ({x: desired, y: (parseFloat(_.get(this.dataByTimestamp, [desired, 'solar_power'])) / 1000).toFixed(1)})),
        'Solar Previous': _.map(this.desiredTimestamps, desired => ({x: desired, y: (parseFloat(_.get(this.previousDayDataByTimestamp, [desired, 'solar_power'])) / 1000).toFixed(1)})),
        'Home': _.map(this.desiredTimestamps, desired => {
          return ({
            x: desired,
            y: (
              parseFloat(_.get(this.dataByTimestamp, [desired, 'battery_power'])) / 1000 +
              parseFloat(_.get(this.dataByTimestamp, [desired, 'grid_power'])) / 1000 +
              parseFloat(_.get(this.dataByTimestamp, [desired, 'solar_power'])) / 1000
            ).toFixed(1),
          })
        }),
      }
    },
    day() {
      return dayjs().add(this.dayOffset, 'days').format('YYYY-MM-DD')
    },
  },
  methods: {
    async updateData() {
      this.loading = true
      await this.updateDayData()
      await this.updatePreviousDayData()
      this.updateChart()
      this.loading = false
    },
    async updateDayData() {
      const resp = await api.DayState.show({id: 'home', params: {dayOffset: this.dayOffset}})
      this.state = resp.data.state
      this.rollup = resp.data.rollup
    },
    async updatePreviousDayData() {
      const resp = await api.DayState.show({id: 'home', params: {dayOffset: this.dayOffset - 1}})
      this.previousDayState = resp.data.state
      this.previousDayRollup = resp.data.rollup
    },
    moveDay(offset) {
      this.dayOffset = this.dayOffset + offset
      this.updateData()
    },
    updateChart() {
      for (let label in this.chartData) {
        const dataset = this.chart.data.datasets.find(ds => ds.label.match(label))
        let addition = ''
        if (label === 'Solar') {
          addition = ` (${(this.rollup.time_series[0].solar_energy_exported / 1000).toFixed(1)}kWh)`
        } else if (label === 'Solar Previous') {
          addition = ` (${(this.previousDayRollup.time_series[0].solar_energy_exported / 1000).toFixed(1)}kWh)`
        } else if (label === 'Grid') {
          const imported = this.rollup.time_series[0].grid_energy_imported
          const exported = this.rollup.time_series[0].grid_energy_exported_from_solar +
            this.rollup.time_series[0].grid_energy_exported_from_battery
          const sum = imported - exported
          addition = ` (${(imported / 1000).toFixed(0)} - ${(exported / 1000).toFixed(0)} = ${(sum / 1000).toFixed(0)}kWh)`
        } else if (label === 'Home') {
          const sum = this.rollup.time_series[0].consumer_energy_imported_from_grid +
            this.rollup.time_series[0].consumer_energy_imported_from_solar +
            this.rollup.time_series[0].consumer_energy_imported_from_battery
          addition = ` (${(sum / 1000).toFixed(1)}kWh)`
        } else if (label === 'Battery') {
          const sum = this.rollup.time_series[0].battery_energy_exported -
            this.rollup.time_series[0].battery_energy_imported_from_grid -
            this.rollup.time_series[0].battery_energy_imported_from_solar
          addition = ` (${(sum / 1000).toFixed(1)}kWh)`
        }
        dataset.label = `${label}${addition}`
        dataset.data = this.chartData[label]
      }
      this.chart.update()
    },
  },
}
</script>

<template>
  <v-app
      v-resize="updateViewportDimensions"
      :style="{
        '--viewport-width-px': `${viewportWidthPx}px`,
        '--viewport-height-px': `${viewportHeightPx}px`,
      }"
      >
    <v-app-bar
      app
      height="40px"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="30"
          height="30"
        />

        <h2>Energy Dashboard</h2>
        <span class="caption ml-4">{{version}}</span>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main
        >
      <v-btn
          absolute
          top
          right
          fab
          color="rgba(255,255,255,0.05)"
          style="margin-top: 2rem"
          @click="swipe('left')"
          >
        <v-icon size="2rem">mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn
          absolute
          top
          left
          fab
          color="rgba(255,255,255,0.05)"
          style="margin-top: 2rem"
          @click="swipe('right')"
          >
        <v-icon size="2rem">mdi-arrow-left</v-icon>
      </v-btn>
      <transition :name="transitionName">
        <router-view :key="$route.name" />
      </transition>
    </v-main>
  </v-app>
</template>

<style scoped lang="sass">
.v-application::v-deep
  background-color: #161818 !important
  .v-app-bar.v-toolbar
    background-color: #161818 !important
    color: white
div ::v-deep .v-application--wrap
  width: var(--viewport-width-px)
  height: calc(var(--viewport-height-px))
  min-height: auto !important

.slide-left-enter-active, .slide-left-leave-active
  transition: transform 0.5s ease
.slide-left-enter-from
  transform: translateX(100%)
.slide-left-leave-to
  transform: translateX(-100%)
.slide-right-enter-active, .slide-right-leave-active
  transition: transform 0.5s ease
.slide-right-enter-from
  transform: translateX(-100%)
.slide-right-leave-to
  transform: translateX(100%)
</style>

<script>
import Vuex from 'vuex'
import _ from 'lodash'
const version = require('../package.json').version

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      transitionName: 'slide-left',
      version,
    }
  },
  created() {
    this.updateViewportDimensions()
  },
  destroyed() {
  },
  computed: {
    ...Vuex.mapGetters('Config', ['viewportWidthPx', 'viewportHeightPx']),
  },
  methods: {
    ...Vuex.mapActions('Config', ['setConfig']),
    updateViewportDimensions() {
      this.setConfig({
        viewportWidthPx: window.innerWidth,
        viewportHeightPx: window.innerHeight,
      })
    },
    swipe(direction) {
      this.transitionName = `slide-${direction}`
      const routeNames = ['dashboard', 'daily']
      const index = _.indexOf(routeNames, this.$route.name)
      if (index > -1) {
        const addition = direction === 'left' ? 1 : -1
        this.$router.replace({name: routeNames[(routeNames.length + index + addition) % routeNames.length]})
      }
    },
  },
}
</script>

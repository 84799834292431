<template>
  <v-container
      fill-height
      class="py-0"
      >
    <v-row
        class="text-center"
        style="height: 100%"
        >
      <v-col
          cols="12"
          class="pb-0"
          align="center"
          >
        <v-img
            :src="require('@/assets/house.png')"
            :width="homeSize.width"
            :height="homeSize.height"
            class="home"
            >
          <div class="pack-info">
            {{batteryPackInfo.transferringFormatted}}kW<br />
            {{batteryPackInfo.percentageLeft}}% remaining<br />
            Battery
          </div>
          <div
              v-if="batteryPackInfo.transferring"
              class="pack-line"
              :class="{negative: batteryPackInfo.transferring < 0}"
              />
          <div class="grid-info">
            {{gridInfo.transferringFormatted}}kW<br />
            Grid
          </div>
          <div
              v-if="gridInfo.transferring"
              class="grid-line"
              :class="{negative: gridInfo.transferring < 0}"
              />
          <div class="home-info">
            {{homeInfo.transferringFormatted}}kW<br />
            Home
          </div>
          <div
              v-if="homeInfo.transferring"
              class="home-line"
              :class="{negative: homeInfo.transferring < 0}"
              >
          </div>
          <div class="solar-info">
            {{solarInfo.transferringFormatted}}kW<br />
            <span v-if="solarInfo.max > 0">Max: {{solarInfo.maxFormatted}}kW<br /></span>
            <span v-if="solarInfo.maxTime">Time: {{solarInfo.maxTime}}<br /></span>
            Solar
          </div>
          <div
              v-if="solarInfo.transferring"
              class="solar-line"
              :class="{negative: solarInfo.transferring < 0}"
              >
          </div>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="sass">
.home
  position: relative
  ::v-deep > *
    color: white
  .pack-info
    position: absolute
    top: 87%
    right: 68.5%
    text-align: right
    line-height: 1.2rem
  .pack-line
    position: absolute
    left: 33.2%
    top: 70.4%
    height: 0.8%
    width: 15%
    background: linear-gradient(90deg, rgba(36,139,29,1) 0%, rgba(36,139,29,1) 7%, rgba(56,56,56,1) 14%, rgba(56,56,56,1) 95%, rgba(36,139,29,1) 100%)
    background-size: 200% 200%
    animation: gradient 1s linear infinite
    transform: rotateZ(167deg)
    &.negative
      transform: rotateZ(-13deg)
  .grid-info
    position: absolute
    top: 89%
    right: 27%
    text-align: right
    line-height: 1.2rem
  .grid-line
    position: absolute
    left: 46.8%
    top: 75.1%
    height: 0.7%
    width: 5%
    background: linear-gradient(90deg, rgba(36,139,29,1) 0%, rgba(36,139,29,1) 7%, rgba(56,56,56,1) 14%, rgba(56,56,56,1) 95%, rgba(36,139,29,1) 100%)
    background-size: 200% 200%
    animation: gradient 1s linear infinite
    transform: rotateZ(90deg)
    &.negative
      transform: rotateZ(270deg)
  .home-info
    position: absolute
    top: 24%
    left: 74%
    text-align: left
    line-height: 1.2rem
  .home-line
    position: absolute
    left: 51.2%
    top: 66.9%
    height: 0.8%
    width: 13.8%
    background: linear-gradient(90deg, rgba(36,139,29,1) 0%, rgba(36,139,29,1) 7%, rgba(56,56,56,1) 14%, rgba(56,56,56,1) 95%, rgba(36,139,29,1) 100%)
    background-size: 200% 200%
    animation: gradient 1s linear infinite
    transform: rotateZ(167deg)
    &.negative
      transform: rotateZ(-13deg)
  .solar-info
    position: absolute
    top: 20%
    left: 48.5%
    text-align: left
    line-height: 1.2rem
  .solar-line
    position: absolute
    left: 45.2%
    top: 63.1%
    height: 0.7%
    width: 8.5%
    background: linear-gradient(90deg, rgba(36,139,29,1) 0%, rgba(36,139,29,1) 7%, rgba(56,56,56,1) 14%, rgba(56,56,56,1) 95%, rgba(36,139,29,1) 100%)
    background-size: 200% 200%
    animation: gradient 1s linear infinite
    transform: rotateZ(270deg)
    &.negative
      transform: rotateZ(90deg)

@keyframes gradient
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 200% 50%
</style>

<script>
import Vuex from 'vuex'
import _ from 'lodash'
import dayjs from 'dayjs'
import api from '@/lib/api'

export default {
  name: 'Dashboard',

  components: {
  },
  data() {
    return {
      state: {},
      dayState: {},
    }
  },
  async created() {
    setInterval(this.updateData, 60000)
    this.updateData()
  },
  computed: {
    ...Vuex.mapGetters('Config', ['viewportWidthPx', 'viewportHeightPx']),
    homeSize() {
      const headerHeight = 40
      const extraVerticalPadding = 12
      const maxHeight = this.viewportHeightPx - headerHeight - extraVerticalPadding

      const extraHorizontalPadding = 0
      const maxWidth = this.viewportWidthPx - extraHorizontalPadding

      const height = maxHeight * (700 / 827) < maxWidth ? maxHeight : maxWidth * (827 / 700)
      const width = maxHeight * (700 / 827) < maxWidth ? maxHeight * (700 / 827) : maxWidth
      return {
        height,
        width
      }
    },
    batteryPackInfo() {
      return {
        total: Math.round(this.state.total_pack_energy / 1000),
        left: Math.round(this.state.energy_left / 1000),
        percentageLeft: Math.round(100 * this.state.energy_left / this.state.total_pack_energy),
        transferring: this.state.battery_power,
        transferringFormatted: Number.parseFloat(this.state.battery_power / 1000).toFixed(1),
      }
    },
    gridInfo() {
      return {
        transferring: this.state.grid_power,
        transferringFormatted: Number.parseFloat(this.state.grid_power / 1000).toFixed(1),
      }
    },
    homeInfo() {
      return {
        transferring: this.state.load_power,
        transferringFormatted: Number.parseFloat(this.state.load_power / 1000).toFixed(1)
      }
    },
    solarInfo() {
      const maxTimeseries = _.maxBy(this.dayState.time_series, t => t.solar_power)
      const maxTime = maxTimeseries ? dayjs(maxTimeseries.timestamp).format('hh:mm') : ''
      const max = maxTimeseries ? maxTimeseries.solar_power : 0
      return {
        transferring: this.state.solar_power,
        transferringFormatted: Number.parseFloat(this.state.solar_power / 1000).toFixed(1),
        max,
        maxFormatted: Number.parseFloat(max / 1000).toFixed(1),
        maxTime
      }
    },
  },
  methods: {
    async updateData() {
      this.state = (await api.HomeState.show({id: 'home'})).data.state
      this.dayState = (await api.DayState.show({id: 'home', params: {dayOffset: 0}})).data.state
    },
  },
}
</script>

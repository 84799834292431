export default {
  namespaced: true,
  state: {
    config: {
      viewportWidthPx: 300,
      viewportHeightPx: 700,
    },
  },
  getters: {
    viewportWidthPx(state) { return state.config.viewportWidthPx },
    viewportHeightPx(state) { return state.config.viewportHeightPx },
  },
  mutations: {
    setConfig(state, config) {
      state.config = config ? {...state.config, ...config} : config
    },
  },
  actions: {
    setConfig({commit}, config) {
      commit('setConfig', config)
    },
  },
}

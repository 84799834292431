import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import localforage from 'localforage'
localforage.setDriver(localforage.LOCALSTORAGE)

import router from '@/router'

const main = async () => {

  Vue.config.productionTip = false

  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

main()

import Vue  from 'vue'
import VueRouter from 'vue-router'

import routes from '@/routes'

Vue.use(VueRouter)
const router = new VueRouter({
  routes
})
// Ignore next line because we don't use from but we have to retrieve it
// eslint-disable-next-line
router.afterEach((to, from) => {
//  if (from.path !== to.path) {
//    api.UserHit.create({params: {userHit: {path: to.path}}})
//  }
})

export default router
